import { useMemo } from 'react';

export function useRedirect() {
  return useMemo(() => {
    return {
      toAddProduct() {
        open('shopify://admin/products', '_top');
      },

      toOrderDetail(id: string) {
        open(`shopify://admin/orders/${id}`, '_top');
      },

      toCustomerDetail(id: string) {
        open(`shopify://admin/customers/${id}`, '_top');
      },

      toDashboard() {
        open(`/dashboard`, '_self');
      },

      toBookings(): void {
        open(`/bookings`, '_self');
      },

      toBookingManage(id: number, returnTo = 'services'): void {
        open(`/bookings/bookings-manage/${id}?returnTo=${returnTo}`, '_self');
      },

      toServices(): void {
        open(`/services`, '_self');
      },

      toCalendar(): void {
        open(`/calendar`, '_self');
      },

      toSettingsGeneral(): void {
        open(`/shops`, '_self');
      },

      toServiceManage(id: number): void {
        open(`/services/services-manage/${id}`, '_self');
      },

      toOutsideUrl(url: string): void {
        open(url, '_top');
      },

      toShopSettingPayment() {
        open(`shopify://admin/settings/payments`, '_top');
      },

      toConfirmPayment(url: string) {
        open(url, '_top');
      },

      toPricing() {
        open(`/pricing`, '_self');
      },

      toSettingsNoti() {
        open(`/shops?selectedTab=1`, '_self');
      },

      toStaff(): void {
        open(`/staff`, '_self');
      },
    };
  }, []);
}
