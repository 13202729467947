import { Loading } from '@shopify/polaris';
import { useRedirect } from '../hooks/useRedirect';
import { useEffect } from 'react';

export default function HomePage() {
  const redirect = useRedirect();

  useEffect(() => {
    redirect.toDashboard();
  }, [redirect]);

  return <Loading />;
}

export const getServerSideProps = async (ctx) => {
  return {
    props: {},
  };
};
